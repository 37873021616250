import React, { useEffect, useState } from "react"
import { graphql } from "gatsby";
import { Layout, SEO, CJumbotron, CBreadCrumb, MicroCmsLayout } from "../../components/_index"

// markup
const SubPage = ({ data }: any) => {
  const news = data.microcmsInfoSrph
  return (
    <Layout>
      <SEO title={`${news.title}`}
        description={news.description}
        ogimage={news.eyecatch?.url}
      />
      <CBreadCrumb data={{
        parent: [
          { label: "お知らせ", path: "/information/" },
        ],
        current: {
          label: news.title
        }
      }} />
      <MicroCmsLayout data={news} />
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query NewsDetail($id: String!) {
    microcmsInfoSrph(id:{eq:$id}) {
      description
      classification
      date_range {
        start
        end
      }
      important
      infoSrphId
      link {
        href
        blank
      }
      pdf {
        url
      }
      publishedAt
      title
      contents {
        fieldId
        heading_text
        section_title
        section_subtitle
        wysiwyg
        box
        caption
        label
        table
        youtube
        htmlcode
        type
        align
        image {
          url
          width
          height
        }
        images {
          caption
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
        }
        keyvalue {
          fieldId
          keyname
          value
          value2
        }
        list{
          value
        }
        tile{
          image {
            url
            width
            height
          }
          heading_text
          wysiwyg
        }
        link {
          href
          blank
        }
        pdf {
          url
        }
        margin {
          margin
        }
        accordion {
          title
          contents {
            fieldId
            heading_text
            wysiwyg
            box
            caption
            label
            table
            youtube
            htmlcode
            type
            align
            image {
              url
              width
              height
            }
            link {
              href
              blank
            }
            pdf {
              url
            }
            margin {
              margin
            }
          }
        }
        left {
          fieldId
          heading_text
          wysiwyg
          box
          caption
          label
          table
          youtube
          htmlcode
          type
          align
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
        right {
          fieldId
          heading_text
          wysiwyg
          box
          caption
          label
          table
          youtube
          htmlcode
          type
          align
          image {
            url
            width
            height
          }
          link {
            href
            blank
          }
          pdf {
            url
          }
          margin {
            margin
          }
        }
      }
    }
  }
`
